import React, { useEffect, useState } from 'react';
import classes from './PopupEdit.module.scss'
import classNames from 'classnames';

import InputDropDown from '../../UI/InputDropDown/InputDropDown';


const PopupAddProduct = ({ isActive, activeItem, parents, hasParents, deactivate, activateDelete, onSubmit }) => {

    const [name, setName] = useState('');
    const [itemParent, setCategoryParent] = useState(null);
    const [options, setOptions] = useState([{id: null, name: 'Нет родителя'}]);

    useEffect(() => {
        if (hasParents) setOptions([{id: null, name: 'Нет родителя'}].concat(parents));
    }, [parents]);

    useEffect(() => {
        setName(activeItem ? activeItem.name : '');
        if (hasParents && !activeItem?.categoryId) setCategoryParent({value: null, label: 'Нет родителя'});
        else if (hasParents) setCategoryParent(activeItem ? {value: activeItem.categoryId, label: parents.find(el => el.id === activeItem.categoryId)?.name} : null);
    }, [activeItem]);

    return (
        <div
            className={isActive ? classNames(classes.PopupAdd, classes.active) : classes.PopupAdd}
            onClick={deactivate}
        >
            <form onSubmit={e => onSubmit(e, name, itemParent?.value)} className={classes.Popup__block} onClick={(e) => { e.stopPropagation() }}>
                <div
                    className={classes.Popup__close}
                    onClick={deactivate}
                >
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M14.9514 5.05005L5.05192 14.9495"
                            stroke="#FFFCFB"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                        />
                        <path
                            d="M5.052 5.05005L14.9515 14.9495"
                            stroke="#FFFCFB"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                        />
                    </svg>
                </div>
                <div className={classes.popup__title}>
                    Редактировать элемент
                </div>
                { hasParents 
                    ?   <div>
                            <InputDropDown 
                                value={itemParent} 
                                placeholder='Выберите Категорию' 
                                old_options={options.filter(el => el.id !== activeItem?.id)} 
                                onChange={setCategoryParent} 
                            />
                        </div>
                    : null }
                <div className={classes.popup__input}>
                    <input value={name} onChange={e => setName(e.target.value)} type="text" placeholder='Категория' />
                </div>
                <div className={classes.popup__delete} onClick={activateDelete}>
                    Удалить элемент
                </div>
                <button className={classes.popup__button}>
                    Редактировать
                </button>
            </form>
        </div> 
    );
}

export default PopupAddProduct;
