import { useEffect, useState } from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';
import classes from './Bouquet.module.scss';

import BouquetService from '../../../../services/bouquetService';
import Loading from '../../../loading/Loading';

import ProductSwiper from '../UI/Swiper/ProductSwiper';
import SelectBody from './UI/SelectBody/SelectBody';
import CategoryService from '../../../../services/categoryService';
import FilterService from '../../../../services/filterService';


const Bouquet = () => {
    const id = useParams().id;
    
    const [isLoading, setLoading]  = useState(true);
    const [isFound, setFound]  = useState(true);
    const [bouquet, setBouquet] = useState();
    const [categories, setCategories] = useState();
    const [colors, setColors] = useState();
    const [flowers, setFlowers] = useState();
    
    const getBouquet = async () => {
        setLoading(true);
        try {
            let response = await BouquetService.getBouquet(id);
            setBouquet(response.data.bouquet);

            response = await CategoryService.get('?raw=true');
            setCategories(response.data.categories);

            response = await FilterService.get('colors');
            setColors(response.data.colors);

            response = await FilterService.get('flowers');
            setFlowers(response.data.flowers);

            setLoading(false);
        } catch (e) {
            if (e.response?.status === 404) setFound(false);
            else setLoading(false); 
            console.error(e.response?.data?.msg);
        } 
    }

    useEffect(() => {
        getBouquet();
    }, []); 

    if (!isFound) {
        return <Navigate to={'/bouquets'} />
    }

    return (
        isLoading 
            ?   <Loading />
            :   <div className={classes.single}>
                    <div className='_container'>
                        <div className={classes.single__title}>
                        <Link to="/bouquets">Букеты</Link> / {bouquet.name}
                        </div>
                        <div className={classes.single__block}>
                            <ProductSwiper images={bouquet.images} id={bouquet.id} type='bouquet' />
                            <SelectBody bouquet={bouquet} categories={categories} allColors={colors} allFlowers={flowers} />
                        </div>
                    </div>
                </div>
    )
}

export default Bouquet