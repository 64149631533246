import api from "../api/axios";

export default class ProductService {
    static async getProducts(query='') {
        return api.get('/products' + query);
    }
    static async getProduct(id) {
        return api.get(`/products/${id}`)
    }
    static async addProduct(name, posifloraId, categoryId, image) {

        const formData = new FormData();
        formData.append("name", name);
        formData.append("posifloraId", posifloraId);
        formData.append("categoryId", categoryId);
        formData.append("image", image);

        return api.post('/products', formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
    }
    static async updateProduct(id, name, category, description, posifloraId, colors, stores) {
        return api.patch(`/products/${id}`, { name, category, description, posifloraId, colors, stores })
    }
    static async deleteProduct(id) {
        return api.delete(`/products/${id}`)
    }
}