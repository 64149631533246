import { useEffect, useState } from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';
import classes from './Product.module.scss';

import ProductService from '../../../../services/productService';
import Loading from '../../../loading/Loading';

import ProductSwiper from '../UI/Swiper/ProductSwiper';
import SelectBody from './UI/SelectBody/SelectBody';
import CategoryService from '../../../../services/categoryService';
import FilterService from '../../../../services/filterService';


const Product = () => {
    const id = useParams().id;
    
    const [isLoading, setLoading]  = useState(true);
    const [isFound, setFound]  = useState(true);
    const [product, setProduct] = useState();
    const [categories, setCategories] = useState();
    const [colors, setColors] = useState();
    
    const getProduct = async () => {
        setLoading(true);
        try {
            let response = await ProductService.getProduct(id);
            setProduct(response.data.product);

            response = await CategoryService.get('?raw=true');
            setCategories(response.data.categories);

            response = await FilterService.get('colors');
            setColors(response.data.colors);

            setLoading(false);
        } catch (e) {
            if (e.response?.status === 404) setFound(false);
            else setLoading(false); 
            console.error(e.response?.data?.msg);
        } 
    }

    useEffect(() => {
        getProduct();
    }, []); 

    if (!isFound) {
        return <Navigate to={'/products'} />
    }

    return (
        isLoading 
            ?   <Loading />
            :   <div className={classes.single}>
                    <div className='_container'>
                        <div className={classes.single__title}>
                            <Link to="/products">Товары</Link> / {product.name}
                        </div>
                        <div className={classes.single__block}>
                            <ProductSwiper images={product.images} id={product.id} type='product' />
                            <SelectBody product={product} categories={categories} allColors={colors} />
                        </div>
                    </div>
                </div>
    )
}

export default Product