import React from 'react';
import classes from './PopupEdit.module.scss'
import classNames from 'classnames';

const PopupDelete = ({ isActive, deactivate, onSubmit }) => {

    return (
        <div
            className={isActive ? classNames(classes.PopupAdd, classes.active) : classes.PopupAdd}
            onClick={deactivate}
        >
            <form onSubmit={e => onSubmit(e)} className={classes.Popup__block} onClick={(e) => { e.stopPropagation() }}>
                <div
                    className={classes.Popup__close}
                    onClick={deactivate}
                >
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M14.9514 5.05005L5.05192 14.9495"
                            stroke="#FFFCFB"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                        />
                        <path
                            d="M5.052 5.05005L14.9515 14.9495"
                            stroke="#FFFCFB"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                        />
                    </svg>
                </div>
                <div className={classes.popup__title}>
                    Удалить элемент
                </div>
                <div className={classes.popup__delete} onClick={deactivate}>
                    <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16 5H1M1 5L5.13793 1M1 5L5.13793 9" stroke="black" />
                    </svg>
                    Вернуться назад
                </div>
                <button className={classes.popup__button}>
                    Удалить
                </button>
            </form>
        </div>
    );
}

export default PopupDelete;
