import { observer } from 'mobx-react-lite';
import { useStore } from '../store/store';

import PopUpAdd from './popups/PopUpAdd';
import PopupEdit from './popups/PopupEdit';
import PopupDelete from './popups/PopupDelete';


const PopUpsAED = () => {

    const {store} = useStore();

    const addItem = async (e, name, parent=null) => {
        try {
            e.preventDefault();

            if (store.popUpType === 'categories') await store.activeService.add(name, parent);
            else await store.activeService.add(store.popUpType, name);

            window.location.reload();
        } catch (e) {
            console.error(e);
        }
    }

    const editItem = async (e, name, parent=null) => {
        try {
            e.preventDefault();

            if (store.popUpType === 'categories') await store.activeService.edit(store.activeItem.id, name, parent);
            else await store.activeService.edit(store.popUpType, store.activeItem.id, name);

            window.location.reload();
        } catch (e) {
            console.error(e);
        }
    }

    const deleteItem = async (e) => {
        try {
            e.preventDefault();

            if (store.popUpType === 'categories') await store.activeService.delete(store.activeItem.id);
            else await store.activeService.delete(store.popUpType, store.activeItem.id);

            window.location.reload();
        } catch (e) {
            console.error(e);
        }
    }

    return (
        <>
            <PopUpAdd
                isActive={store.popUpAddActive}
                parents={store.itemParents}
                hasParents={store.popUpType === 'categories'}
                deactivate={() => store.deactivateAdd()}
                onSubmit={addItem}
            />
            <PopupEdit
                isActive={store.popUpEditActive}
                activeItem={store.activeItem}
                parents={store.itemParents}
                hasParents={store.popUpType === 'categories'}
                deactivate={() => store.deactivateEdit()}
                activateDelete={() => store.activateDelete(store.popUpType, store.activeItem, store.activeService, true)}
                onSubmit={editItem}
            />
            <PopupDelete
                isActive={store.popUpDeleteActive}
                deactivate={() => store.deactivateDelete()}
                onSubmit={deleteItem}
            />
        </>
    )
}

export default observer(PopUpsAED)