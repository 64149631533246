import api from "../api/axios";

export default class FilterService {
    static async get(type, query='') {
        return api.get(`/${type}` + query);
    }
    static async add(type, name, parentId) {
        return api.post(`/${type}`, { name, parentId });
    }
    static async edit(type, id, name, parentId) {
        return api.patch(`/${type}/${id}`, { name, parentId });
    }
    static async delete(type, id) {
        return api.delete(`/${type}/${id}`);
    }
}