import { useEffect, useState } from "react";

import ProductService from "../../../../../../services/productService";
import Loading from '../../../../../loading/Loading';

import Product from '../../../UI/Product/Product';

const Products = ({ onPageStart, filters, activeItems, setFilters, setMinPrice, setMaxPrice, setActiveItems }) => {

    const [isLoading, setLoading]  = useState(false);
    const [products, setProducts] = useState([]);

    const getProducts = async (filter='') => {
        setLoading(true);
        try {
            const response = await ProductService.getProducts(filter);
            setProducts(response.data.products);
            if (onPageStart && response.data?.minPrice !== null && response.data?.maxPrice !== null) {
                setMinPrice(response.data.minPrice);
                setMaxPrice(response.data.maxPrice);
                setFilters(oldFilters => ({
                    ...oldFilters,
                    priceMin: response.data.minPrice,
                    priceMax: response.data.maxPrice
                }));
            }
        } catch (e) {
            console.error(e.response?.data?.msg);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (!onPageStart) {
            let params = new URLSearchParams();
            for (let key of Object.keys(filters)) {
                if (filters[key].length !== 0 ) params.append(key, filters[key]);
            }
            getProducts('?' + params);
        }
        
    }, [filters]);

    useEffect(() => {
        getProducts();
    }, []);


    return (
        isLoading 
            ?   <Loading />
            :   products.map((product) => {
                    return <Product
                        id={product.id}
                        key={product.id}
                        name={product.name}
                        link={'/products/' + product.id}
                        image={product.images[0]?.name}
                        category={product.category?.name ? product.category.name : 'Нет категории'}
                        price={product.price ? product.price : 0}
                        quantity={product.quantity ? product.quantity : 0}
                        activeItems={activeItems}
                        setActiveItems={setActiveItems}
                    />
                })
    )
}

export default Products;