import React, { useState } from 'react';
import classes from './popupChange.module.scss'
import classNames from 'classnames';
import DropDownProducts from '../../UI/DropDownProducts/DropDownProducts';


const PopupChange = ({ items, setItems, activePopup, setActivePopup }) => {
    const [value, setValue] = useState();
    const addItem = (item) => {
        if (items.filter(el => el.id === item.id).length > 0) {
            setItems(items.map(el => {
                if (el.id === item.id) {
                    return { ...el, quantity: el.quantity + 1 }
                }
                return el;
            }));
        } else {
            setItems(oldItems => [...oldItems,
            {
                id: item.id,
                name: item.name,
                price: 0,
                quantity: 1,
                available: true
            }]);
        }
    }

    const changePrice = (id, price) => {
        setItems(items.map(el => {
            if (el.id === id) {
                return { ...el, price: price < 0 ? 0 : price }
            }
            return el;
        }));
    }

    const changeAvailability = (id, available) => {
        setItems(items.map(el => {
            if (el.id === id) {
                return { ...el, available: available }
            }
            return el;
        }));
    }

    const changeQuantity = (id, oldQuantity, newQuantity) => {
        if (oldQuantity + newQuantity <= 0) {
            deleteItem(id);
        } else {
            setItems(items.map(el => {
                if (el.id === id) {
                    return { ...el, quantity: oldQuantity + newQuantity }
                }
                return el;
            }));
        }
    }
    const chageInputValue = (id, newValue) => {
        if (!isNaN(newValue)) {
            setItems(items.map(el => {
                if (el.id === id) {
                    return { ...el, quantity: newValue }
                }
                return el;
            }));
        }
    }

    const deleteItem = (id) => {
        setItems(items.filter(el => el.id !== id));
    }

    return (
        <div
            className={activePopup ? classNames(classes.PopupAdd, classes.active) : classes.PopupAdd}
            onClick={() => { setActivePopup(false) }}
        >
            <div className={classes.Popup__block} onClick={(e) => { e.stopPropagation() }}>
                <div
                    className={classes.Popup__close}
                    onClick={() => { setActivePopup(false) }}
                >
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M14.9514 5.05005L5.05192 14.9495"
                            stroke="#FFFCFB"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                        />
                        <path
                            d="M5.052 5.05005L14.9515 14.9495"
                            stroke="#FFFCFB"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                        />
                    </svg>
                </div>
                <div className={classes.popup__title}>
                    Изменить состав
                </div>
                <DropDownProducts
                    onClick={addItem}
                    type={'stores'}
                    name={'Склад'}
                    placeholder={'Поиск склада'}
                />
                <div className={classes.popup__items}>
                    {items.map(item => {
                        return <div key={item.id} className={classes["popup-item"]}>
                            <div className={classes["popup-item__title"]}>
                                {item.name}
                            </div>
                            <div className={classes["popup-item__price"]}>
                                <input type="number" value={item.price} onChange={e => changePrice(item.id, e.target.value)} />
                            </div>
                            <div className={classes["popup-item__quantity"]}>
                                <div className={classes["popup-item__minus"]} onClick={() => changeQuantity(item.id, item.quantity, -1)}>
                                    -
                                </div>
                                <div className={classes["popup-item__num"]}>
                                    <input type="text" value={item.quantity} onChange={(e) => chageInputValue(item.id, e.target.value)} />
                                </div>
                                <div className={classes["popup-item__plus"]} onClick={() => changeQuantity(item.id, item.quantity, 1)}>
                                    +
                                </div>
                            </div>
                            <div className={classes["popup-item__available"]}>
                                Доступен: <input type="checkbox" checked={item.available} onChange={() => changeAvailability(item.id, !item.available)} />
                            </div>
                            <div className={classes["popup-item__del"]} onClick={() => deleteItem(item.id)}>
                                <svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M21.2726 9.88339H0.727419C0.292742 9.88339 0 9.60175 0 9.17501V6.35001C0 5.22343 1.02016 4.2334 2.2 4.2334H19.8C20.9798 4.2334 22 5.22343 22 6.35001V9.17501C22 9.45666 21.7073 9.88339 21.2726 9.88339ZM1.46371 8.47516H20.5363V6.35001C20.5363 5.93181 20.2435 5.65016 19.8 5.65016H11H2.2C1.75645 5.65016 1.46371 5.93181 1.46371 6.35001V8.47516Z" fill="#391E3B" />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M16.8737 24H5.12855C3.07936 24 1.46484 22.4467 1.46484 20.4666V9.17519C1.46484 8.75699 1.75759 8.47534 2.20113 8.47534C2.63581 8.47534 2.92855 8.75699 2.92855 9.17519V20.4666C2.92855 21.6018 3.95759 22.5833 5.12855 22.5833H16.8737C18.0447 22.5833 19.0737 21.6018 19.0737 20.4666V9.17519C19.0737 8.75699 19.3665 8.47534 19.8011 8.47534C20.2447 8.47534 20.5374 8.75699 20.5374 9.17519V20.4666C20.5374 22.4467 18.9229 24 16.8737 24Z" fill="#391E3B" />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M14.6594 4.94161H13.1957V2.11661C13.1957 1.69841 12.903 1.41677 12.4595 1.41677H9.53203C9.08848 1.41677 8.79574 1.69841 8.79574 2.11661V4.94161H7.33203V2.11661C7.33203 0.990029 8.35219 0 9.53203 0H12.4595C13.6393 0 14.6594 0.990029 14.6594 2.11661V4.94161Z" fill="#391E3B" />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M6.59567 19.7662C6.15212 19.7662 5.85938 19.4846 5.85938 19.0579V13.4079C5.85938 12.9897 6.15212 12.708 6.59567 12.708C7.03034 12.708 7.33196 12.9897 7.33196 13.4079V19.0579C7.33196 19.4846 7.03034 19.7662 6.59567 19.7662Z" fill="#391E3B" />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M11.0019 19.7662C10.5584 19.7662 10.2656 19.4846 10.2656 19.0579V13.4079C10.2656 12.9897 10.5584 12.708 11.0019 12.708C11.4455 12.708 11.7382 12.9897 11.7382 13.4079V19.0579C11.7382 19.4846 11.4455 19.7662 11.0019 19.7662Z" fill="#391E3B" />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M15.4004 19.7662C14.9657 19.7662 14.6641 19.4846 14.6641 19.0579V13.4079C14.6641 12.9897 14.9657 12.708 15.4004 12.708C15.8439 12.708 16.1366 12.9897 16.1366 13.4079V19.0579C16.1366 19.4846 15.8439 19.7662 15.4004 19.7662Z" fill="#391E3B" />
                                </svg>
                            </div>
                        </div>
                    })}
                </div>
            </div>
        </div>
    );
}

export default PopupChange;
