import api from "../api/axios";

export default class UserService {
    static async getUsers() {
        return api.get('/users');
    }
    static async addUser(login, name, password, password2) {
        return api.post('/users', { login, name, password, password2 });
    }
    static async editUser(id, login, name, password, password2) {
        return api.patch(`/users/${id}`, { login, name, password, password2 });
    }
    static async deleteUser(id) {
        return api.delete(`/users/${id}`);
    }
}