import { Link } from "react-router-dom";
import { API_URL } from "../../../../../api/axios";
import classes from './Product.module.scss';

const Product = ({ id, image, name, link, category, price, quantity, activeItems, setActiveItems }) => {
    return (
        <Link to={link}>
            <div className={classes.product}>
                <div className={classes.product__checkbox} onClick={e => e.stopPropagation()}>
                    <input
                        type="checkbox"
                        id={`product${id}`}
                        onChange={() => {
                            if (activeItems.includes(id)) setActiveItems(activeItems.filter(el => el !== id));
                            else setActiveItems([...activeItems, id]);
                        }}
                        checked={activeItems.includes(id)}
                    />
                    <label htmlFor={`product${id}`}>
                        <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 7.66667L6.42095 13L19 1" stroke="#FFFCFB" strokeWidth="2" strokeLinecap="round" />
                        </svg>
                    </label>
                </div>
                <div className={classes.product__img}>
                    <img src={image ? `${API_URL}/images/${image}` : null} alt="" />
                </div>
                <div className={classes.product__name}>
                    {name}
                </div>
                <div className={classes.product__category}>
                    {category}
                </div>
                <div className={classes.product__price}>
                    {price} р
                </div>
                <div className={classes.product__col}>
                    {quantity} шт.
                </div>
            </div>
        </Link>
    );
}

export default Product;
