import React, { useState } from 'react';
import classes from './SelectBody.module.scss'

import Button from '../../../../../../UI/button/Button';
import InputDropDown from '../../../../../../UI/InputDropDown/InputDropDown';
import SelectBodyItem from './UI/SelectBodyItem';

import PopupChangeBouquet from '../../../../../popups/popupChangeBouquet';
import BouquetService from '../../../../../../services/bouquetService';


const SelectBody = ({ bouquet, categories, allColors, allFlowers }) => {

    const [title, setTitle] = useState(bouquet.name);
    const [category, setCategory] = useState(bouquet.category ? { value: bouquet.category.id, label: bouquet.category.name } : { value: null, label: 'Нет категории' })
    const [description, setDescription] = useState(bouquet.description ? bouquet.description : '');
    const [posifloraId, setPosifloraId] = useState(bouquet.posifloraId);
    const [available, setAvailable] = useState(bouquet.available);
    const [colors, setColors] = useState(bouquet.colors.map(el => { return { value: el.id, label: el.name } }));
    const [flowers, setFlowers] = useState(bouquet.flowers.map(el => { return { value: el.id, label: el.name } }));
    const [products, setProducts] = useState(bouquet.products.map(el => { return { id: el.id, name: el.name, category: el.category?.name, image: el.images[0]?.name, price: el.price, quantity: el.bouquets_has_products.quantity } }));
    const [price, setPrice] = useState(bouquet.price);

    const [active, setActive] = useState(false);
    const [activeEdit, setActiveEdit] = useState(-1);

    function updateEditMode(id) {
        setActiveEdit(activeEdit !== id ? id : -1);
    }

    const saveChanges = async () => {
        try {
            await BouquetService.updateBouquet(bouquet.id, title, category.value, description, posifloraId, available, colors.map(el => el.value), flowers.map(el => el.value), products, price);
            window.location.reload();
        } catch (e) {
            console.error(e);
        }
    }

    return (
        <div className={classes.SelectBody}>
            <div className={classes.SelectBody__title}>
                {activeEdit !== 1
                    ? <>
                        <span>{title}</span>
                        <svg onClick={() => updateEditMode(1)} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.8623 18.6795L1 24.7743L7.26411 23.0248M2.8623 18.6795L7.26411 23.0248M2.8623 18.6795L16.3499 5.2325M7.26411 23.0248L20.6953 9.59368M22.6704 7.61851L24.5673 5.72164C25.3555 4.93341 25.3472 3.65289 24.5488 2.87496L23.01 1.37564C22.227 0.612693 20.9765 0.61987 20.2022 1.39176L18.3815 3.20702M22.6704 7.61851L18.3815 3.20702M22.6704 7.61851L20.6953 9.59368M18.3815 3.20702L16.3499 5.2325M16.3499 5.2325L20.6953 9.59368" stroke="#391E3B" strokeLinejoin="round" />
                        </svg>
                    </>
                    : <input
                        type="text"
                        value={title}
                        onChange={(e) => { setTitle(e.target.value) }}
                        onBlur={() => updateEditMode(1)}
                        autoFocus
                    />
                }
            </div>
            <div className={classes.SelectBody__categories}>
                {activeEdit !== 2
                    ? <>
                        <span>{category.label}</span>
                        <svg onClick={() => updateEditMode(2)} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.8623 18.6795L1 24.7743L7.26411 23.0248M2.8623 18.6795L7.26411 23.0248M2.8623 18.6795L16.3499 5.2325M7.26411 23.0248L20.6953 9.59368M22.6704 7.61851L24.5673 5.72164C25.3555 4.93341 25.3472 3.65289 24.5488 2.87496L23.01 1.37564C22.227 0.612693 20.9765 0.61987 20.2022 1.39176L18.3815 3.20702M22.6704 7.61851L18.3815 3.20702M22.6704 7.61851L20.6953 9.59368M18.3815 3.20702L16.3499 5.2325M16.3499 5.2325L20.6953 9.59368" stroke="#391E3B" strokeLinejoin="round" />
                        </svg>
                    </>
                    : <InputDropDown
                        value={category}
                        placeholder='Выберите Категорию'
                        old_options={[{ id: null, name: 'Нет категории' }].concat(categories)}
                        onChange={setCategory}
                        onBlur={() => updateEditMode(2)}
                        autoFocus={true}
                    />
                }
            </div>
            <div className={classes.SelectBody__text}>
                {activeEdit !== 3
                    ? <>
                        <span>{description ? description : 'Пустое описание'}</span>
                        <svg onClick={() => updateEditMode(3)} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.8623 18.6795L1 24.7743L7.26411 23.0248M2.8623 18.6795L7.26411 23.0248M2.8623 18.6795L16.3499 5.2325M7.26411 23.0248L20.6953 9.59368M22.6704 7.61851L24.5673 5.72164C25.3555 4.93341 25.3472 3.65289 24.5488 2.87496L23.01 1.37564C22.227 0.612693 20.9765 0.61987 20.2022 1.39176L18.3815 3.20702M22.6704 7.61851L18.3815 3.20702M22.6704 7.61851L20.6953 9.59368M18.3815 3.20702L16.3499 5.2325M16.3499 5.2325L20.6953 9.59368" stroke="#391E3B" strokeLinejoin="round" />
                        </svg>
                    </>
                    : <textarea
                        value={description}
                        onChange={e => setDescription(e.target.value)}
                        onBlur={() => updateEditMode(3)}
                        autoFocus
                    />
                }
            </div>
            <div className={classes.SelectBody__text}>
                {activeEdit !== 4
                    ? <>
                        <span>Id Posiflora: {posifloraId}</span>
                        <svg onClick={() => updateEditMode(4)} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.8623 18.6795L1 24.7743L7.26411 23.0248M2.8623 18.6795L7.26411 23.0248M2.8623 18.6795L16.3499 5.2325M7.26411 23.0248L20.6953 9.59368M22.6704 7.61851L24.5673 5.72164C25.3555 4.93341 25.3472 3.65289 24.5488 2.87496L23.01 1.37564C22.227 0.612693 20.9765 0.61987 20.2022 1.39176L18.3815 3.20702M22.6704 7.61851L18.3815 3.20702M22.6704 7.61851L20.6953 9.59368M18.3815 3.20702L16.3499 5.2325M16.3499 5.2325L20.6953 9.59368" stroke="#391E3B" strokeLinejoin="round" />
                        </svg>
                    </>
                    : <input
                        type="text"
                        value={posifloraId}
                        onChange={(e) => { setPosifloraId(e.target.value) }}
                        onBlur={() => updateEditMode(4)}
                        autoFocus
                    />
                }
            </div>
            <div className={classes.SelectBody__colors}>
                {activeEdit !== 5
                    ? <>
                        <span>Цвета: {colors?.length > 0 ? colors.map(el => el.label).join(', ') : 'нет цветов'}</span>
                        <svg onClick={() => updateEditMode(5)} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.8623 18.6795L1 24.7743L7.26411 23.0248M2.8623 18.6795L7.26411 23.0248M2.8623 18.6795L16.3499 5.2325M7.26411 23.0248L20.6953 9.59368M22.6704 7.61851L24.5673 5.72164C25.3555 4.93341 25.3472 3.65289 24.5488 2.87496L23.01 1.37564C22.227 0.612693 20.9765 0.61987 20.2022 1.39176L18.3815 3.20702M22.6704 7.61851L18.3815 3.20702M22.6704 7.61851L20.6953 9.59368M18.3815 3.20702L16.3499 5.2325M16.3499 5.2325L20.6953 9.59368" stroke="#391E3B" strokeLinejoin="round" />
                        </svg>
                    </>
                    : <InputDropDown
                        value={colors}
                        placeholder='Выберите Цвета'
                        old_options={allColors}
                        onChange={setColors}
                        onBlur={() => updateEditMode(5)}
                        autoFocus={true}
                        isMulti={true}
                    />
                }
            </div>
            <div className={classes.SelectBody__colors}>
                {activeEdit !== 6
                    ? <>
                        <span>Цветки: {flowers?.length > 0 ? flowers.map(el => el.label).join(', ') : 'нет цветов'}</span>
                        <svg onClick={() => updateEditMode(6)} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.8623 18.6795L1 24.7743L7.26411 23.0248M2.8623 18.6795L7.26411 23.0248M2.8623 18.6795L16.3499 5.2325M7.26411 23.0248L20.6953 9.59368M22.6704 7.61851L24.5673 5.72164C25.3555 4.93341 25.3472 3.65289 24.5488 2.87496L23.01 1.37564C22.227 0.612693 20.9765 0.61987 20.2022 1.39176L18.3815 3.20702M22.6704 7.61851L18.3815 3.20702M22.6704 7.61851L20.6953 9.59368M18.3815 3.20702L16.3499 5.2325M16.3499 5.2325L20.6953 9.59368" stroke="#391E3B" strokeLinejoin="round" />
                        </svg>
                    </>
                    : <InputDropDown
                        value={flowers}
                        placeholder='Выберите Цветки'
                        old_options={allFlowers}
                        onChange={setFlowers}
                        onBlur={() => updateEditMode(6)}
                        autoFocus={true}
                        isMulti={true}
                    />
                }
            </div>
            <div className={classes.SelectBody__storage}>
                <div className={classes.checkbox}>
                    <input checked={available} onChange={() => setAvailable(!available)} type="checkbox" id='checkbox-filter' />
                    <label htmlFor="checkbox-filter">
                        <div className={classes["checkbox-block"]}>
                            <svg width="22" height="17" viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 8.77778L7.02328 15L21 1" stroke="#FFFCFB" strokeWidth="2" strokeLinecap="round" />
                            </svg>
                        </div>
                        В наличии
                    </label>
                </div>
            </div>
            <div className={classes.SelectBody__subtitle}>
                <span>Состав</span>
                <div className={classes.SelectBody__button} onClick={() => { setActive(true) }}>
                    Изменить состав
                </div>
            </div>
            <div className={classes.SelectBody__items}>
                {products.map(product => {
                    return <SelectBodyItem
                        key={product.id}
                        image={product.image}
                        name={product.name}
                        category={product.category ? product.category : 'Нет категории'}
                        price={product.price ? product.price : 0}
                        quantity={product.quantity}
                    />
                })}
            </div>
            <div className={classes.SelectBody__subtitle}>
                <span>Итого</span>
                <div className={classes.SelectBody__price}>
                    {activeEdit !== 7
                        ? <>
                            <span>{price} ₽</span>
                            <svg onClick={() => updateEditMode(7)} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.8623 18.6795L1 24.7743L7.26411 23.0248M2.8623 18.6795L7.26411 23.0248M2.8623 18.6795L16.3499 5.2325M7.26411 23.0248L20.6953 9.59368M22.6704 7.61851L24.5673 5.72164C25.3555 4.93341 25.3472 3.65289 24.5488 2.87496L23.01 1.37564C22.227 0.612693 20.9765 0.61987 20.2022 1.39176L18.3815 3.20702M22.6704 7.61851L18.3815 3.20702M22.6704 7.61851L20.6953 9.59368M18.3815 3.20702L16.3499 5.2325M16.3499 5.2325L20.6953 9.59368" stroke="#391E3B" strokeLinejoin="round" />
                            </svg>
                        </>
                        : <input
                            type="number"
                            value={price}
                            onChange={(e) => { setPrice(e.target.value) }}
                            onBlur={() => updateEditMode(7)}
                            autoFocus
                        />
                    }
                </div>
            </div>
            <div className={classes.SelectBody__btn}>
                <Button solid={true} text='Сохранить изменения' onClick={() => saveChanges()} />
            </div>
            <PopupChangeBouquet
                items={products}
                activePopup={active}
                setItems={setProducts}
                setActivePopup={setActive}
            />
        </div>
    );
}

export default SelectBody;
