import api from "../api/axios";

export default class ImageService {
    static async addImage(image, productId=null, bouquetId=null) {

        const formData = new FormData();
        formData.append("image", image);
        if (productId) formData.append("productId", productId);
        if (bouquetId) formData.append("bouquetId", bouquetId);

        return api.post(`/images`, formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
    }
    static async deleteImage(id) {
        return api.delete(`/images/${id}`)
    }
}