import React from 'react';
import classes from './popupChange.module.scss'
import classNames from 'classnames';
import DropDownProducts from '../../UI/DropDownProducts/DropDownProducts';
import ItemChangeBouquetPopup from '../../UI/ItemChangeBouquetPopup/ItemChangeBouquetPopup';


const PopupChange = ({ items, setItems, activePopup, setActivePopup }) => {

    const addItem = (item) => {
        if (items.filter(el => el.id === item.id).length > 0) {
            setItems(items.map(el => {
                if (el.id === item.id) {
                    return { ...el, quantity: el.quantity + 1 }
                }
                return el;
            }));
        } else {
            setItems(oldItems => [...oldItems,
            {
                id: item.id,
                name: item.name,
                category: item.category?.name,
                image: item.images[0]?.name,
                quantity: 1
            }]);
        }
    }

    const changeQuantity = (id, oldQuantity, newQuantity) => {
        if (oldQuantity + newQuantity <= 0) {
            deleteItem(id);
        } else {
            setItems(items.map(el => {
                if (el.id === id) {
                    return { ...el, quantity: oldQuantity + newQuantity }
                }
                return el;
            }));
        }
    }

    const deleteItem = (id) => {
        setItems(items.filter(el => el.id !== id));
    }

    return (
        <div
            className={activePopup ? classNames(classes.PopupAdd, classes.active) : classes.PopupAdd}
            onClick={() => { setActivePopup(false) }}
        >
            <div className={classes.Popup__block} onClick={(e) => { e.stopPropagation() }}>
                <div
                    className={classes.Popup__close}
                    onClick={() => { setActivePopup(false) }}
                >
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M14.9514 5.05005L5.05192 14.9495"
                            stroke="#FFFCFB"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                        />
                        <path
                            d="M5.052 5.05005L14.9515 14.9495"
                            stroke="#FFFCFB"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                        />
                    </svg>
                </div>
                <div className={classes.popup__title}>
                    Изменить состав
                </div>
                <DropDownProducts
                    onClick={addItem}
                    type={'products'}
                    name={'Продукты'}
                    placeholder={'Поиск продукта'}
                />
                <div className={classes.popup__items}>
                    {items.map(item => {
                        return <ItemChangeBouquetPopup
                            key={item.id}
                            item={item}
                            changeQuantity={changeQuantity}
                            deleteItem={deleteItem}
                        />
                    })}
                </div>
            </div>
        </div>
    );
}

export default PopupChange;
