import React, { useState } from 'react';
import classes from './PopUpAddProduct.module.scss'
import classNames from 'classnames';
import Input from '../../UI/Input/Input';


const PopupAddUser = ({ isActive, deactivate, onSubmit }) => {

    const [name, setName] = useState('');
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');

    return (
        <div
            className={isActive ? classNames(classes.PopupAdd, classes.active) : classes.PopupAdd}
            onClick={() => { deactivate(false) }}
        >
            <form onSubmit={e => onSubmit(e, login, name, password, password2)} className={classes.Popup__block} onClick={(e) => { e.stopPropagation() }}>
                <div
                    className={classes.Popup__close}
                    onClick={() => { deactivate(false) }}
                >
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M14.9514 5.05005L5.05192 14.9495"
                            stroke="#FFFCFB"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                        />
                        <path
                            d="M5.052 5.05005L14.9515 14.9495"
                            stroke="#FFFCFB"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                        />
                    </svg>
                </div>
                <div className={classes.popup__title}>
                    Добавить пользователя
                </div>
                <div className={classes.popup__content}>
                    <div className={classNames(classes.popup__body, classes.user)}>
                        <Input value={name} onChange={setName} placeholder='Имя пользователя' />
                        <Input value={login} onChange={setLogin} placeholder='Логин пользователя' />
                        <Input value={password} onChange={setPassword} placeholder='Пароль пользователя' />
                        <Input value={password2} onChange={setPassword2} placeholder='Повторите пароль пользователя' />
                    </div>
                </div>
                <button className={classes.popup__button}>
                    Добавить
                </button>
            </form>

        </div>
    );
}

export default PopupAddUser;
