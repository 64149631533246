import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import classes from './Users.module.scss';

import { useStore } from '../../../store/store';
import Loading from '../../loading/Loading';
import UserService from '../../../services/userService';

import Title from '../../../UI/title/Title';
import User from './User/User';

import PopupDelete from '../../popups/PopupDelete';
import PopupAddUser from '../../popups/PopupAddUser';


const Users = () => {

    const {store} = useStore();

    const [isLoading, setLoading] = useState(false)
    const [users, setUsers] = useState([]);

    const [activeUser, setActiveUsers] = useState();

    const [activeDelete, setActiveDelete] = useState(false);
    const [activeAdd, setActiveAdd] = useState(false);

    const getUsers = async () => {
        setLoading(true);
        try {
            const response = await UserService.getUsers();
            setUsers(response.data.users);
        } catch (e) {
            console.error(e.response?.data?.msg);
        } finally {
            setLoading(false);
        }
    }

    const activateDelete = (id) => {
        setActiveUsers(id);
        setActiveDelete(true);
    }

    const addUser = async (e, login, name, password, password2) => {
        try {
            e.preventDefault();
            await UserService.addUser(login, name, password, password2);
            window.location.reload();
        } catch (e) {
            console.error(e);
        }
    }

    const deleteUser = async (e) => {
        try {
            e.preventDefault();
            await UserService.deleteUser(activeUser);
            window.location.reload();
        } catch (e) {
            console.error(e);
        }
    }

    useEffect(() => {
        getUsers();
    }, []);

    return (
        store.role < 5 
            ?   <Navigate to="/" replace />
            :   isLoading 
                    ?   <Loading />
                    :   <div className={classes.users}>
                            <div className="_container">
                                <div className={classes.users__top}>
                                    <Title text='Пользователи' />
                                    <button className={classes.button} onClick={() => setActiveAdd(true)}>
                                        Добавить пользователя
                                    </button>
                                </div>
                                <div className={classes.users__names}>
                                    <div className={classes.users__name}>Имя</div>
                                    <div className={classes.users__name}>Логин</div>
                                </div>
                                <div className={classes.users__items}>
                                    {users.map(user => {
                                        return <User
                                            key={user.id}
                                            user={user}
                                            activateDelete={activateDelete}
                                        />
                                    })}
                                </div>
                                <PopupDelete
                                    isActive={activeDelete}
                                    deactivate={() => setActiveDelete(false)}
                                    onSubmit={deleteUser}
                                />
                                <PopupAddUser
                                    isActive={activeAdd}
                                    deactivate={setActiveAdd}
                                    onSubmit={addUser}
                                />
                            </div>
                        </div>
    );
}

export default Users;
