import { useEffect } from "react";
import { Routes, Route } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStore } from "./store/store";

import './App.scss';

import Layout from "./components/Layout";
import RequireAuth from './components/RequireAuth';
import Loading from './components/loading/Loading';

import Login from "./components/main/login/Login";
import Categories from "./components/main/categories/Categories";
import Products from "./components/main/catalog/products/Catalog";
import Product from "./components/main/single/product/Product";
import Bouquets from "./components/main/catalog/bouquets/Catalog";
import Bouquet from "./components/main/single/bouquet/Bouquet";
import Users from "./components/main/users/Users";


function App() {

    const {store} = useStore();
    
    useEffect(() => {
        if (localStorage.getItem('accessToken')) {
            store.chechAuth();
        }
    }, []);

    return (
        <div className="wrapper">
            { store.isLoading
            ? <Loading />
            : <Routes>
                <Route to="/" element={<Layout />}>
                    {/* public routes */}
                    <Route path="login" element={<Login />} />

                    {/* protected routes */}
                    <Route element={<RequireAuth />}>
                        <Route index element={<Categories />} />

                        <Route path="products" element={<Products />} />
                        <Route path="products/:id" element={<Product />} />

                        <Route path="bouquets" element={<Bouquets />} />
                        <Route path="bouquets/:id" element={<Bouquet />} />

                        <Route path="users" element={<Users />} />
                    </Route>
                </Route>
            </Routes> }
        </div>
        
    )
}

export default observer(App);
