import React, { useCallback, useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import classes from './PriceFilter.module.scss'
import classNames from "classnames";

const PriceFilter = ({ min, max, name, onChangeActive, active, handleChange }) => {


    const [minVal, setMinVal] = useState(min);
    const [maxVal, setMaxVal] = useState(max);
    const minValRef = useRef(min);
    const maxValRef = useRef(max);
    const range = useRef(null);
    
    useEffect(() => {
        setMinVal(min);
        minValRef.current = min;
        setMaxVal(max);
        maxValRef.current = max;
    }, [min, max])

    const getPercent = useCallback(
        (value) => Math.round(((value - min) / (max - min)) * 100),
        [min, max]
    );

    useEffect(() => {
        const minPercent = getPercent(minVal);
        const maxPercent = getPercent(maxValRef.current);

        if (range.current) {
            range.current.style.left = `${minPercent}%`;
            range.current.style.width = `${maxPercent - minPercent}%`;
        }
    }, [minVal, getPercent]);
    useEffect(() => {
        const minPercent = getPercent(minValRef.current);
        const maxPercent = getPercent(maxVal);

        if (range.current) {
            range.current.style.width = `${maxPercent - minPercent}%`;
        }
    }, [maxVal, getPercent]);

    return (
        <div className={classes.DropDownFilter}>
            <div
                className={active ? classNames(classes.DropDownFilter__btn, classes.active) : classes.DropDownFilter__btn}
                onClick={onChangeActive}
            >
                {name}
                <svg width="18" height="11" viewBox="0 0 18 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1L9 9L17 1" stroke="#391E3B" strokeWidth="2" strokeLinecap="round" />
                </svg>
            </div>
            <div className={active ? classNames(classes.DropDownFilter__block, classes.active) : classes.DropDownFilter__block}>
                <div className={classes.container}>
                    <input
                        type="range"
                        min={min}
                        max={max}
                        value={minVal}
                        onChange={(event) => {
                            const value = Math.min(Number(event.target.value), maxVal - 1);
                            setMinVal(value);
                            minValRef.current = value;
                        }}
                        onMouseUp={() => handleChange("priceMin", minVal)}
                        onTouchEnd={() => handleChange("priceMin", minVal)}
                        className={classNames(classes.thumb, classes.thumb__left)}
                        style={{ zIndex: minVal > max - 100 && "5" }}
                    />
                    <input
                        type="range"
                        min={min}
                        max={max}
                        value={maxVal}
                        onChange={(event) => {
                            const value = Math.max(Number(event.target.value), minVal + 1);
                            setMaxVal(value);
                            maxValRef.current = value;
                        }}
                        onMouseUp={() => handleChange("priceMax", maxVal)}
                        onTouchEnd={() => handleChange("priceMax", maxVal)}
                        className={classNames(classes.thumb, classes.thumb__left)}
                    />
                    <div className={classes.slider}>
                        <div className={classes.slider__track} />
                        <div ref={range} className={classes.slider__range} />
                        <div className={classes.values}>
                            <div className={classes.value}>
                                <span>Мин. цена</span>
                                <input readOnly type="text" value={minVal} />
                            </div>
                            <div className={classes.value}>
                                <span>Макс. цена</span>
                                <input readOnly type="text" value={maxVal} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

PriceFilter.propTypes = {
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
};

export default PriceFilter;
