import { useEffect, useState } from "react";
import classes from './Categories.module.scss';

import { useStore } from "../../../store/store";

import CategoryService from "../../../services/categoryService";
import Loading from '../../loading/Loading';

import Title from '../../../UI/title/Title';
import Button from '../../../UI/button/Button';

import Category from './UI/category/Category';


const Categories = () => {

    const {store} = useStore();
    
    const [isLoading, setLoading]  = useState(false);
    const [categories, setCategories] = useState([]);

    const getCategories = async () => {
        setLoading(true);
        try {
            const response = await CategoryService.get();
            setCategories(response.data.categories);
        } catch (e) {
            console.error(e.response?.data?.msg);
        } finally {
            setLoading(false);
        }
    }

    const activateAddPopUp = () => {
        store.setItemParents(categories);
        store.activateAdd('categories', CategoryService);
    }

    const activateEditPopUp = (category) => {
        store.setItemParents(categories);
        store.activateEdit('categories', category, CategoryService);
    }

    const activateDeletePopUp = (category) => {
        store.activateDelete('categories', category, CategoryService);
    }

    useEffect(() => {
        getCategories();
    }, []);

    return (
        isLoading 
            ?   <Loading />
            :   <section className={classes.categories}>
                    <div className='_container'>
                        <div className={classes.categories__top}>
                            <Title text='Категории' />
                            <Button text='Добавить категорию' onClick={() => activateAddPopUp()} />
                        </div>
                        <div className={classes.categories__block}>
                            {categories.map(category => {
                                return  <Category 
                                            categoryData={category} 
                                            key={category.id} 
                                            activateEdit={activateEditPopUp}
                                            activateDelete={activateDeletePopUp}
                                        />
                            })}
                        </div>
                    </div>
                </section>
    )
}

export default Categories