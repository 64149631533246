import React from 'react';
import classes from './SelectBodyItem.module.scss'


const SelectBodyItem = ({ name, quantity, price, available }) => {
    return (
        <div className={classes.SelectBody__item}>
            <div className={classes.SelectBody__item__name}>
                {name}
            </div>
            <div className={classes.SelectBody__item__price}>
                {price} р
            </div>
            <div className={classes.SelectBody__item__amount}>
                {quantity} шт.
            </div>
            <div className={classes.SelectBody__item__amount}>
                {available ? 'Доступен' : 'Недоступен'}
            </div>
        </div>
    );
}

export default SelectBodyItem;
