import classes from './QuantityFilter.module.scss';
import classNames from 'classnames';
import { useState } from 'react';


const QuantityFilter = ({ name, onChange, active, value, handleChange }) => {

    const [quantity, setQuantity] = useState(value)

    return (
        <div className={classes.DropDownFilter}>
            <div
                className={active ? classNames(classes.DropDownFilter__btn, classes.active) : classes.DropDownFilter__btn}
                onClick={onChange}
            >
                {name}
                <svg width="18" height="11" viewBox="0 0 18 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1L9 9L17 1" stroke="#391E3B" strokeWidth="2" strokeLinecap="round" />
                </svg>
            </div>
            <div className={active ? classNames(classes.DropDownFilter__block, classes.active) : classes.DropDownFilter__block}>
                <div className={classes.title}>
                    Введите количество
                </div>
                <div className={classes.inputs}>
                    <div className={classes.plus} onClick={() => { setQuantity(parseInt(quantity) + 1); handleChange('quantity', parseInt(quantity) + 1) }}>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8 1V15" stroke="#58135D" strokeWidth="1.5" strokeLinecap="round" />
                            <path d="M1 8H15" stroke="#58135D" strokeWidth="1.5" strokeLinecap="round" />
                        </svg>
                    </div>
                    <div className={classes.input}>
                        <input type="text" placeholder='кол-во' value={quantity} onChange={e => setQuantity(e.target.value)} onBlur={() => handleChange('quantity', quantity)} />
                    </div>
                    <div className={classes.munis} onClick={() => { if (value > 0) { setQuantity(parseInt(quantity) - 1); handleChange('quantity', parseInt(quantity) - 1) } }}>
                        <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 1L15 1" stroke="#58135D" strokeWidth="1.5" strokeLinecap="round" />
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default QuantityFilter;
