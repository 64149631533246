import React, { useState } from 'react';
import classes from './SelectBody.module.scss'

import Button from '../../../../../../UI/button/Button';
import InputDropDown from '../../../../../../UI/InputDropDown/InputDropDown';
import SelectBodyItem from './UI/SelectBodyItem';

import PopupChangeProduct from '../../../../../popups/popupChangeProduct';
import ProductService from '../../../../../../services/productService';


const SelectBody = ({ product, categories, allColors }) => {

    const [title, setTitle] = useState(product.name);
    const [category, setCategory] = useState(product.category ? { value: product.category.id, label: product.category.name } : { value: null, label: 'Нет категории' })
    const [description, setDescription] = useState(product.description ? product.description : '');
    const [posifloraId, setPosifloraId] = useState(product.posifloraId);
    const [colors, setColors] = useState(product.colors.map(el => { return { value: el.id, label: el.name } }));
    const [stores, setStores] = useState(product.stores.map(el => {
        return {
            id: el.id, name: el.name,
            price: el.stores_has_products?.price,
            quantity: el.stores_has_products?.quantity,
            available: el.stores_has_products?.available
        }
    }));

    const [active, setActive] = useState(false);
    const [activeEdit, setActiveEdit] = useState(-1);

    function updateEditMode(id) {
        setActiveEdit(activeEdit !== id ? id : -1);
    }

    const saveChanges = async () => {
        try {
            await ProductService.updateProduct(product.id, title, category.value, description, posifloraId, colors.map(el => el.value), stores);
            window.location.reload();
        } catch (e) {
            console.error(e);
        }
    }

    return (
        <div className={classes.SelectBody}>
            <div className={classes.SelectBody__title}>
                {activeEdit !== 1
                    ? <>
                        <span>{title}</span>
                        <svg onClick={() => updateEditMode(1)} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.8623 18.6795L1 24.7743L7.26411 23.0248M2.8623 18.6795L7.26411 23.0248M2.8623 18.6795L16.3499 5.2325M7.26411 23.0248L20.6953 9.59368M22.6704 7.61851L24.5673 5.72164C25.3555 4.93341 25.3472 3.65289 24.5488 2.87496L23.01 1.37564C22.227 0.612693 20.9765 0.61987 20.2022 1.39176L18.3815 3.20702M22.6704 7.61851L18.3815 3.20702M22.6704 7.61851L20.6953 9.59368M18.3815 3.20702L16.3499 5.2325M16.3499 5.2325L20.6953 9.59368" stroke="#391E3B" strokeLinejoin="round" />
                        </svg>
                    </>
                    : <input
                        type="text"
                        value={title}
                        onChange={(e) => { setTitle(e.target.value) }}
                        onBlur={() => updateEditMode(1)}
                        autoFocus
                    />
                }
            </div>
            <div className={classes.SelectBody__categories}>
                {activeEdit !== 2
                    ? <>
                        <span>{category.label}</span>
                        <svg onClick={() => updateEditMode(2)} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.8623 18.6795L1 24.7743L7.26411 23.0248M2.8623 18.6795L7.26411 23.0248M2.8623 18.6795L16.3499 5.2325M7.26411 23.0248L20.6953 9.59368M22.6704 7.61851L24.5673 5.72164C25.3555 4.93341 25.3472 3.65289 24.5488 2.87496L23.01 1.37564C22.227 0.612693 20.9765 0.61987 20.2022 1.39176L18.3815 3.20702M22.6704 7.61851L18.3815 3.20702M22.6704 7.61851L20.6953 9.59368M18.3815 3.20702L16.3499 5.2325M16.3499 5.2325L20.6953 9.59368" stroke="#391E3B" strokeLinejoin="round" />
                        </svg>
                    </>
                    : <InputDropDown
                        value={category}
                        placeholder='Выберите Категорию'
                        old_options={[{ id: null, name: 'Нет категории' }].concat(categories)}
                        onChange={setCategory}
                        onBlur={() => updateEditMode(2)}
                        autoFocus={true}
                    />
                }
            </div>
            <div className={classes.SelectBody__text}>
                {activeEdit !== 3
                    ? <>
                        <span>{description ? description : 'Пустое описание'}</span>
                        <svg onClick={() => updateEditMode(3)} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.8623 18.6795L1 24.7743L7.26411 23.0248M2.8623 18.6795L7.26411 23.0248M2.8623 18.6795L16.3499 5.2325M7.26411 23.0248L20.6953 9.59368M22.6704 7.61851L24.5673 5.72164C25.3555 4.93341 25.3472 3.65289 24.5488 2.87496L23.01 1.37564C22.227 0.612693 20.9765 0.61987 20.2022 1.39176L18.3815 3.20702M22.6704 7.61851L18.3815 3.20702M22.6704 7.61851L20.6953 9.59368M18.3815 3.20702L16.3499 5.2325M16.3499 5.2325L20.6953 9.59368" stroke="#391E3B" strokeLinejoin="round" />
                        </svg>
                    </>
                    : <textarea
                        value={description}
                        onChange={e => setDescription(e.target.value)}
                        onBlur={() => updateEditMode(3)}
                        autoFocus
                    />
                }
            </div>
            <div className={classes.SelectBody__text}>
                {activeEdit !== 4
                    ? <>
                        <span>Id Posiflora: {posifloraId}</span>
                        <svg onClick={() => updateEditMode(4)} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.8623 18.6795L1 24.7743L7.26411 23.0248M2.8623 18.6795L7.26411 23.0248M2.8623 18.6795L16.3499 5.2325M7.26411 23.0248L20.6953 9.59368M22.6704 7.61851L24.5673 5.72164C25.3555 4.93341 25.3472 3.65289 24.5488 2.87496L23.01 1.37564C22.227 0.612693 20.9765 0.61987 20.2022 1.39176L18.3815 3.20702M22.6704 7.61851L18.3815 3.20702M22.6704 7.61851L20.6953 9.59368M18.3815 3.20702L16.3499 5.2325M16.3499 5.2325L20.6953 9.59368" stroke="#391E3B" strokeLinejoin="round" />
                        </svg>
                    </>
                    : <input
                        type="text"
                        value={posifloraId}
                        onChange={(e) => { setPosifloraId(e.target.value) }}
                        onBlur={() => updateEditMode(4)}
                        autoFocus
                    />
                }
            </div>
            <div className={classes.SelectBody__colors}>
                {activeEdit !== 5
                    ? <>
                        <span>Цвета: {colors?.length > 0 ? colors.map(el => el.label).join(', ') : 'нет цветов'}</span>
                        <svg onClick={() => updateEditMode(5)} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.8623 18.6795L1 24.7743L7.26411 23.0248M2.8623 18.6795L7.26411 23.0248M2.8623 18.6795L16.3499 5.2325M7.26411 23.0248L20.6953 9.59368M22.6704 7.61851L24.5673 5.72164C25.3555 4.93341 25.3472 3.65289 24.5488 2.87496L23.01 1.37564C22.227 0.612693 20.9765 0.61987 20.2022 1.39176L18.3815 3.20702M22.6704 7.61851L18.3815 3.20702M22.6704 7.61851L20.6953 9.59368M18.3815 3.20702L16.3499 5.2325M16.3499 5.2325L20.6953 9.59368" stroke="#391E3B" strokeLinejoin="round" />
                        </svg>
                    </>
                    : <InputDropDown
                        value={colors}
                        placeholder='Выберите Цвета'
                        old_options={allColors}
                        onChange={setColors}
                        onBlur={() => updateEditMode(5)}
                        autoFocus={true}
                        isMulti={true}
                    />
                }
            </div>
            <div className={classes.SelectBody__subtitle}>
                <span>Наличие</span>
                <div className={classes.SelectBody__button} onClick={() => { setActive(true) }}>
                    Изменить наличие
                </div>
            </div>
            <div className={classes.SelectBody__items}>
                {stores.map(store => {
                    return <SelectBodyItem
                        key={store.id}
                        name={store.name}
                        price={store.price}
                        quantity={store.quantity}
                        available={store.available}
                    />
                })}
            </div>
            <div className={classes.SelectBody__subtitle}>
                <span>Итого</span>
                <div className={classes.SelectBody__price}>
                    <span>{stores.length > 0 ? Math.min(...stores.map(el => el.price)) : 0} ₽</span>
                </div>
            </div>
            <div className={classes.SelectBody__btn}>
                <Button solid={true} text='Сохранить изменения' onClick={() => saveChanges()} />
            </div>
            <PopupChangeProduct
                items={stores}
                activePopup={active}
                setItems={setStores}
                setActivePopup={setActive}
            />
        </div>
    );
}

export default SelectBody;
