import React, { useState } from 'react';
import classes from './Input.module.scss';


const Input = ({ value, onChange, placeholder, solid = false, password = false }) => {
    const [chageType, setChageType] = useState(password);
    return (
        <div className={solid ? classes.InputSolid : classes.popup__input}>
            <input type={chageType ? 'password' : 'text'} value={value} onChange={e => onChange(e.target.value)} placeholder={placeholder} required />
            {password ?
                <div className={classes.inputGlass} onClick={() => setChageType(!chageType)}>
                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_501_760)">
                            <path d="M1.9071 12.3154C1.9071 12.3154 5.9071 4.31543 12.9071 4.31543C19.9071 4.31543 23.9071 12.3154 23.9071 12.3154C23.9071 12.3154 19.9071 20.3154 12.9071 20.3154C5.9071 20.3154 1.9071 12.3154 1.9071 12.3154Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M12.9071 15.3154C14.564 15.3154 15.9071 13.9723 15.9071 12.3154C15.9071 10.6586 14.564 9.31543 12.9071 9.31543C11.2503 9.31543 9.9071 10.6586 9.9071 12.3154C9.9071 13.9723 11.2503 15.3154 12.9071 15.3154Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </g>
                        <defs>
                            <clipPath id="clip0_501_760">
                                <rect width="24" height="24" fill="391E3B" transform="translate(0.907104 0.31543)" />
                            </clipPath>
                        </defs>
                    </svg>
                </div>
                : null
            }
        </div>
    );
}

export default Input;
