import React from 'react';
import classes from './SelectBodyItem.module.scss'
import { API_URL } from '../../../../../../../api/axios';

const SelectBodyItem = ({ image, name, category, price, quantity }) => {
    return (
        <div className={classes.SelectBody__item}>
            <div className={classes.SelectBody__item__img}>
                <img src={image ? `${API_URL}/images/${image}` : ''} alt="" />
            </div>
            <div className={classes.SelectBody__item__name}>
                {name}
            </div>
            <div className={classes.SelectBody__item__category}>
                {category}
            </div>
            <div className={classes.SelectBody__item__price}>
                {price} р
            </div>
            <div className={classes.SelectBody__item__amount}>
                {quantity} шт.
            </div>
        </div>
    );
}

export default SelectBodyItem;
