import React, { useState } from 'react';
import classes from './Category.module.scss';
import classNames from 'classnames';

const Category = ({ categoryData, activateEdit, activateDelete }) => {

    const [opened, setOpened] = useState(false);

    return (
        <div className={classes.category}>
            <button
                onClick={() => { setOpened(!opened) }}
                className={opened ? classNames(classes.category__btn, classes.active) : classes.category__btn}
            >
                <span>
                    {categoryData.name}
                </span>
                <svg width="18" height="11" viewBox="0 0 18 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1L9 9L17 1" stroke="#FFFCFB" strokeWidth="2" strokeLinecap="round" />
                </svg>
            </button>
            <ul className={opened ? classNames(classes.category__list, classes.active) : classes.category__list}>
                {categoryData.subCategories.map(category => {
                    return <li key={category.id}>
                        <div className={classes.category__link}>
                            {category.name}
                            <div className={classes.category__body}>
                                <div className={classes.category__edit} onClick={() => activateEdit(category)}>
                                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2.48984 15.0797L1 20L6.01129 18.5877M2.48984 15.0797L6.01129 18.5877M2.48984 15.0797L13.2799 4.22414M6.01129 18.5877L16.7562 7.74487M18.3363 6.15034L19.5867 4.8886C20.3663 4.10185 20.3581 2.83127 19.5683 2.0547L18.897 1.39462C18.1124 0.623173 16.852 0.630407 16.0763 1.41081L14.9052 2.58899M18.3363 6.15034L14.9052 2.58899M18.3363 6.15034L16.7562 7.74487M14.9052 2.58899L13.2799 4.22414M13.2799 4.22414L16.7562 7.74487" stroke="#202020" strokeLinejoin="round" />
                                    </svg>
                                </div>
                                <div className={classes.category__delete} onClick={() => activateDelete(category)}>
                                    <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M19.3387 9.05978H0.66129C0.266129 9.05978 0 8.8016 0 8.41043V5.82085C0 4.78814 0.927419 3.88062 2 3.88062H18C19.0726 3.88062 20 4.78814 20 5.82085V8.41043C20 8.6686 19.7339 9.05978 19.3387 9.05978ZM1.33065 7.7689H18.6694V5.82085C18.6694 5.43749 18.4032 5.17932 18 5.17932H2C1.59677 5.17932 1.33065 5.43749 1.33065 5.82085V7.7689Z" fill="#202020" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M15.3397 22H4.66231C2.79941 22 1.33167 20.5761 1.33167 18.7611V8.41057C1.33167 8.02722 1.59779 7.76904 2.00102 7.76904C2.39618 7.76904 2.66231 8.02722 2.66231 8.41057V18.7611C2.66231 19.8016 3.59779 20.7013 4.66231 20.7013H15.3397C16.4042 20.7013 17.3397 19.8016 17.3397 18.7611V8.41057C17.3397 8.02722 17.6059 7.76904 18.001 7.76904C18.4042 7.76904 18.6704 8.02722 18.6704 8.41057V18.7611C18.6704 20.5761 17.2026 22 15.3397 22Z" fill="#202020" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M13.3285 4.52981H11.9978V1.94023C11.9978 1.55688 11.7317 1.2987 11.3285 1.2987H8.66718C8.26395 1.2987 7.99782 1.55688 7.99782 1.94023V4.52981H6.66718V1.94023C6.66718 0.907527 7.59459 0 8.66718 0H11.3285C12.401 0 13.3285 0.907527 13.3285 1.94023V4.52981Z" fill="#202020" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M5.99785 18.1187C5.59462 18.1187 5.32849 17.8605 5.32849 17.4694V12.2902C5.32849 11.9069 5.59462 11.6487 5.99785 11.6487C6.39301 11.6487 6.6672 11.9069 6.6672 12.2902V17.4694C6.6672 17.8605 6.39301 18.1187 5.99785 18.1187Z" fill="#202020" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M10.0018 18.1188C9.59853 18.1188 9.3324 17.8607 9.3324 17.4695V12.2903C9.3324 11.907 9.59853 11.6488 10.0018 11.6488C10.405 11.6488 10.6711 11.907 10.6711 12.2903V17.4695C10.6711 17.8607 10.405 18.1188 10.0018 18.1188Z" fill="#202020" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M14.0021 18.1187C13.607 18.1187 13.3328 17.8605 13.3328 17.4694V12.2902C13.3328 11.9069 13.607 11.6487 14.0021 11.6487C14.4053 11.6487 14.6715 11.9069 14.6715 12.2902V17.4694C14.6715 17.8605 14.4053 18.1187 14.0021 18.1187Z" fill="#202020" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </li>
                })}
            </ul>
        </div>
    );
}

export default Category;
