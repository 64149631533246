import api from "../api/axios";

export default class BouquetService {
    static async getBouquets(query='') {
        return api.get('/bouquets' + query);
    }
    static async getBouquet(id) {
        return api.get(`/bouquets/${id}`)
    }
    static async addBouquet(name, posifloraId, categoryId, image) {

        const formData = new FormData();
        formData.append("name", name);
        formData.append("posifloraId", posifloraId);
        formData.append("categoryId", categoryId);
        formData.append("image", image);

        return api.post('/bouquets', formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
    }
    static async updateBouquet(id, name, category, description, posifloraId, available, colors, flowers, products, price) {
        return api.patch(`/bouquets/${id}`, { name, category, description, posifloraId, available, colors, flowers, products, price })
    }
    static async deleteBouquet(id) {
        return api.delete(`/bouquets/${id}`)
    }
}