import { useEffect, useState } from "react";

import BouquetService from "../../../../../../services/bouquetService";
import Loading from '../../../../../loading/Loading';

import Product from '../../../UI/Product/Product';

const Products = ({ onPageStart, filters, activeItems, setFilters, setMinPrice, setMaxPrice, setActiveItems }) => {

    const [isLoading, setLoading] = useState(false);
    const [bouquets, setBouquets] = useState([]);

    const getBouquets = async (filter = '') => {
        setLoading(true);
        try {
            const response = await BouquetService.getBouquets(filter);
            setBouquets(response.data.bouquets);
            if (onPageStart && response.data?.minPrice !== null && response.data?.maxPrice !== null) {
                setMinPrice(response.data.minPrice);
                setMaxPrice(response.data.maxPrice);
                setFilters(oldFilters => ({
                    ...oldFilters,
                    priceMin: response.data.minPrice,
                    priceMax: response.data.maxPrice
                }));
            }
        } catch (e) {
            console.error(e.response?.data?.msg);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (!onPageStart) {
            let params = new URLSearchParams();
            for (let key of Object.keys(filters)) {
                if (filters[key].length !== 0) params.append(key, filters[key]);
            }
            getBouquets('?' + params);
        }

    }, [filters]);

    useEffect(() => {
        getBouquets();
    }, []);


    return (
        isLoading
            ? <Loading />
            : bouquets.map((bouquet) => {
                return <Product
                    id={bouquet.id}
                    key={bouquet.id}
                    name={bouquet.name}
                    link={'/bouquets/' + bouquet.id}
                    image={bouquet.images[0]?.name}
                    category={bouquet.category?.name ? bouquet.category.name : 'Нет категории'}
                    price={bouquet.price}
                    quantity={bouquet.quantity ? parseInt(bouquet.quantity) : 0}
                    activeItems={activeItems}
                    setActiveItems={setActiveItems}
                />
            })
    )
}

export default Products;