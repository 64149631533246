import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classes from './Filter.module.scss';

import BouquetService from '../../../../../../services/bouquetService';

import DropDownFilter from '../../../../../../UI/DropDownFilter/DropDownFilter';

import SearchFilter from '../../../UI/SearchFilter/SearchFilter';
import PriceFilter from '../../../UI/PriceFilter/PriceFilter';
import QuantityFilter from '../../../UI/QuantityFilter/QuantityFilter';
import PopupAddProduct from '../../../../../popups/PopupAddProduct';

const Filters = ({ setOnPageStart, filters, setFilters, minPrice, maxPrice }) => {
    const navigate = useNavigate();
    const [categories, setCategories] = useState([]);
    const [colors, setColors] = useState([]);
    const [flowers, setFlowers] = useState([]);

    const [activePopup, setActivePopup] = useState(false);
    const [activeMenu, setActiveMenu] = useState(-1);
    function updateActiveElement(id) {
        setActiveMenu(activeMenu !== id ? id : -1);
    }

    const handleChange = (name, value) => {
        setFilters(oldFilters => ({
            ...oldFilters,
            [name]: value
        }));
        setOnPageStart(false);
    }

    const addBouquet = async (e, name, posifloraId, categoryId, image) => {
        try {
            e.preventDefault();
            const data = await BouquetService.addBouquet(name, posifloraId, categoryId, image);
            navigate(`/bouquets/${data.data.bouquetId}`);

        } catch (e) {
            console.error(e);
        }
    }


    return (
        <div className={classes.Filters}>
            <SearchFilter
                value={filters.search}
                handleChange={handleChange}
                placeholder='Поиск'
            />
            <DropDownFilter
                values={filters.categories}
                active={1 === activeMenu}
                items={categories}
                setItems={setCategories}
                onChange={() => updateActiveElement(1)}
                handleChange={handleChange}
                type={'categories'}
                name={'Категория'}
                placeholder={'Поиск категории'}
            />
            <DropDownFilter
                values={filters.colors}
                active={2 === activeMenu}
                items={colors}
                setItems={setColors}
                onChange={() => updateActiveElement(2)}
                handleChange={handleChange}
                type={'colors'}
                name={'Цвет'}
                placeholder={'Поиск цвета'}
            />
            <DropDownFilter
                values={filters.flowers}
                active={3 === activeMenu}
                items={flowers}
                setItems={setFlowers}
                onChange={() => updateActiveElement(3)}
                handleChange={handleChange}
                type={'flowers'}
                name={'Цветок'}
                placeholder={'Поиск цветка'}
            />
            <PriceFilter
                onChangeActive={() => updateActiveElement(4)}
                active={4 === activeMenu}
                name='Цена'
                min={minPrice}
                max={maxPrice}
                handleChange={handleChange}
            />
            <QuantityFilter
                onChange={() => updateActiveElement(5)}
                active={5 === activeMenu}
                name='Количество'
                value={filters.quantity}
                handleChange={handleChange}
            />
            <div className={classes.checkbox}>
                <input type="checkbox" id='checkbox-filter' checked={filters.isAvailable} onChange={() => handleChange('isAvailable', !filters.isAvailable)} />
                <label htmlFor="checkbox-filter">
                    <div className={classes["checkbox-block"]}>
                        <svg width="22" height="17" viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 8.77778L7.02328 15L21 1" stroke="#FFFCFB" strokeWidth="2" strokeLinecap="round" />
                        </svg>
                    </div>
                    В наличии
                </label>
            </div>
            <button className={classes.button} onClick={() => { setActivePopup(true) }}>
                Добавить букет
            </button>
            {activePopup ? <PopupAddProduct
                popUpActive={activePopup}
                setPopUpActive={setActivePopup}
                title='Добавление букета'
                btnText='Добавить букет'
                inputText='Название букета'
                categories={categories}
                formOnSubmit={addBouquet}
            /> :
                null}
        </div>

    );
}

export default Filters;
