import { useState } from 'react';
import classes from './PopUpAdd.module.scss'
import classNames from 'classnames';
import InputDropDown from '../../UI/InputDropDown/InputDropDown';



const PopUpAdd = ({ isActive, deactivate, parents, hasParents, onSubmit }) => {
    const [checked, setChecked] = useState(false);
    const [categoryParent, setCategoryParent] = useState(null);
    const [name, setName] = useState('');

    return (
        <div
            className={isActive ? classNames(classes.PopUpAdd, classes.active) : classes.PopUpAdd}
            onClick={deactivate}
        >
            <form onSubmit={e => onSubmit(e, name, categoryParent?.value)} className={classes.PopUp__block} onClick={(e) => e.stopPropagation()}>
                <div
                    className={classes.PopUp__close}
                    onClick={deactivate}
                >
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M14.9514 5.05005L5.05192 14.9495"
                            stroke="#FFFCFB"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                        />
                        <path
                            d="M5.052 5.05005L14.9515 14.9495"
                            stroke="#FFFCFB"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                        />
                    </svg>
                </div>
                <div className={classes.popup__title}>
                    Добавить элемент
                </div>
                {hasParents > 0
                    ?   <>
                            <div className={classes.popup__radio}>
                                <input
                                    type="radio"
                                    name="selectAdd"
                                    id="category"
                                    checked={!checked}
                                    onChange={() => setChecked(!checked)}
                                />
                                <label htmlFor="category">Категорию</label>
                            </div>
                            <div className={classes.popup__radio}>
                                <input
                                    type="radio"
                                    name="selectAdd"
                                    id="subcategory"
                                    checked={checked}
                                    onChange={() => setChecked(!checked)}
                                />
                                <label htmlFor="subcategory">Подкатегорию</label>
                            </div>
                            {checked
                                ? <InputDropDown placeholder='Выберите Категорию' old_options={parents} onChange={setCategoryParent} />
                                : null
                            }
                        </>
                    : null
                }
                <div className={classes.popup__input}>
                    <input value={name} onChange={e => setName(e.target.value)} type="text" placeholder={checked ? 'Подкатегория' : 'Категория'} required />
                </div>
                <button type='submit' className={classes.popup__button}>
                    Добавить
                </button>
            </form>

        </div>
    );
}

export default PopUpAdd;
