import classes from './PopUpAddProduct.module.scss'
import classNames from 'classnames';
import Input from '../../UI/Input/Input';
import InputDropDown from '../../UI/InputDropDown/InputDropDown';
import { useState } from 'react';


const PopupAddProduct = ({ title, btnText, inputText, popUpActive, setPopUpActive, categories, formOnSubmit }) => {

    const [name, setName] = useState('');
    const [posifloraId, setPosifloraId] = useState('');
    const [categoryId, setCategoryId] = useState(null);
    const [image, setImage] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);

    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            setSelectedImage(URL.createObjectURL(event.target.files[0]));
            setImage(event.target.files[0]);
            console.log(event.target.files[0]);
        }
    }

    return (
        <div
            className={popUpActive ? classNames(classes.PopupAdd, classes.active) : classes.PopupAdd}
            onClick={() => { setPopUpActive(false) }}
        >
            <form
                onSubmit={e => formOnSubmit(e, name, posifloraId, categoryId?.value, image)} className={classes.Popup__block} onClick={(e) => { e.stopPropagation() }}>
                <div
                    className={classes.Popup__close}
                    onClick={() => { setPopUpActive(false) }}
                >
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M14.9514 5.05005L5.05192 14.9495"
                            stroke="#FFFCFB"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                        />
                        <path
                            d="M5.052 5.05005L14.9515 14.9495"
                            stroke="#FFFCFB"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                        />
                    </svg>
                </div>
                <div className={classes.popup__title}>
                    {title}
                </div>
                <div className={classes.popup__content}>
                    <div className={classes.popup__img}>
                        <input type="file" id='img' accept=".png, .jpg, .jpeg" onChange={onImageChange} />
                        <label htmlFor="img">
                            <svg width="43" height="33" viewBox="0 0 43 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="0.5" y="0.5" width="41" height="31.6667" rx="4.5" stroke="#B4B4B4" />
                                <rect x="24.0225" y="5.53979" width="7.4" height="7.4" rx="3.7" stroke="#B4B4B4" />
                                <path d="M0.421875 23.3316L10.0016 17.0299C11.7131 15.904 13.9384 15.9361 15.6167 17.1109L22.1223 21.6648C23.5943 22.6953 25.5061 22.8566 27.13 22.0874L41.7919 15.1423" stroke="#B4B4B4" strokeLinejoin="round" />
                            </svg>
                            Загрузить изображение
                        </label>
                        <img src={selectedImage} alt="" className={selectedImage ? classes.activeimg : ""} />
                    </div>
                    <div className={classes.popup__body}>
                        <Input placeholder={inputText} value={name} onChange={setName} />
                        <Input placeholder='id товара в Posiflora' value={posifloraId} onChange={setPosifloraId} />
                        <InputDropDown placeholder='Выберите категорию' value={categoryId} onChange={setCategoryId} old_options={categories} />
                    </div>
                </div>
                <button type='submit' className={classes.popup__button}>
                    {btnText}
                </button>
            </form>

        </div>
    );
}

export default PopupAddProduct;
