import { useStore } from "../../store/store";
import { Link } from 'react-router-dom';
import classes from './Header.module.scss';


const Header = () => {
    
    const {store} = useStore();

    return (
        <header className={classes.header} id="header">
            <div className='_container'>
                <ul className={classes.header__list}>
                    <li>
                        <Link to="/" className={classes.header__link}>
                            Категории
                        </Link>
                    </li>
                    <li>
                        <Link to="/products" className={classes.header__link}>
                            Товары
                        </Link>
                    </li>
                    <li>
                        <Link to="/bouquets" className={classes.header__link}>
                            Букеты
                        </Link>
                    </li>
                    {store.role >= 5 
                        ?   <li>
                                <Link to="/users" className={classes.header__link}>
                                    Пользователи
                                </Link>
                            </li> 
                        :   null
                    }
                    <li>
                        <button onClick={() => store.Logout()}>Выйти</button>
                    </li>
                </ul>
            </div>
        </header>
    )
}

export default Header