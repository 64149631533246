import api from "../api/axios";

export default class CategoryService {
    static async get(query='') {
        return api.get('/categories' + query);
    }
    static async add(name, parentId) {
        return api.post('/categories', { name, parentId });
    }
    static async edit(id, name, parentId) {
        return api.patch(`/categories/${id}`, { name, parentId });
    }
    static async delete(id) {
        return api.delete(`/categories/${id}`);
    }
}